@import "~styles/config";

.button{
  padding: 16px;
  border-radius: 12px;
  border: none;
  font-family: $font-family;
  font-size: 14px;
  font-weight: $font-semibold;
  width: 100%;
  outline: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &__primary{
    background: $color-highlight;
    color: $dark-dark-color;
  }
  &__secondary{
    background: $light-dark-color;
    color: #fff;
    font-weight: $font-medium;
  }
  &__transparent{
    background: transparent;
    color: #fff;
    font-weight: $font-medium;
  }
}
.icon{
  align-self: center;
  display: inline-flex;
  &__left{
    margin-right: 10px;
  }
  &__left{
    margin-left: 10px;
  }
}