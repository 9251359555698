.headerIconLink{
  width: 20px;
  height: 20px;
  display: block;
  text-decoration: none;
  align-self: center;
  cursor: pointer;
  svg{
    width: 100%;
    height: 100%;
  }
}