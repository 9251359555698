@import "~styles/config";

.title{
  font-family: $font-family;
  margin: 0;
}

.heading.1{
  font-size: 34px;
  font-weight: $font-bold;
  letter-spacing: -.3px;
  line-height: 1.2;
}
.heading.2{
  font-size: 28px;
}
.heading.3{
  font-size: 22px;
  font-weight: $font-semibold;
}
.heading.4{
  font-size: 20px;
}
.heading.5{
  font-size: 17px;
  font-weight: $font-regular;
}
.heading.6{
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: $font-bold;
  line-height: 1.6;
}