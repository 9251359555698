@import "~styles/config";

.welcomeScreenContainer{
  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    &__content{
      h3{
      margin-top: 24px;
      }
      &__text{
        font-size: 17px;
        line-height: 1.7;
        font-family: $font-family;
        color: #FFFFFF;
      }
    }
    &__buttons{
      margin-top: 64px;
      button{
        pointer-events: all;
        margin: 12px 0;
        cursor: pointer;
        &:first-child{
          margin-top: 0;
        }
      }
    }
  }
}

.formContainer{
  overflow: auto;
  &__addOption{
    font-family: $font-family;
    width: 100%;
    text-align: left;
    appearance: none;
    color: #ffffff;
    border: none;
    background: $light-dark-color;
    font-size: 16px;
    margin: 0;
    padding: 16px;
    cursor: pointer;
    outline: none;
  }
  &__wrapper{
    padding: 0 16px;
    button{
      pointer-events: all;
      margin-top: 64px;
      cursor: pointer;
      &:last-child{
        margin: 0;
      }
    }
    h3{
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
  &__imageContainer{
    padding: 0 16px;
    height: 300px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__upload{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    display: block;
    margin-top: 54px;
    label{
      display: flex;
      flex-direction: column;
      position: relative;
      align-self: center;
      color: #FFFFFF;
      font-family: $font-family;
      text-align: center;
      font-size: 22px;
      font-weight: $font-semibold;
      &:first-child{
        margin-bottom: 22px;
      }
      input{
        color: transparent;
        width: 0px;
        height: 0px;
        align-self: center;
        margin-bottom: 20px;
      }
      &::before{
        content: "";
        width: 175px;
        height: 175px;
        position: relative;
        display: block;
        border-radius: 6px;
        align-self: center;
      }
    }
    .camera{
      &::before{
        background: url('../../assets/images/selfie-icon.svg') center no-repeat, $light-dark-color;
      }
    }
    .image{
      &::before{
        background: url('../../assets/images/upload-icon.svg') center no-repeat, $light-dark-color;
      }
    }
  }
}

.inputWrapper{
  padding: 11px 0;
  
  &__languagesWrapper{
    label{
      margin: 0 !important;
      height: 60px;
      line-height: 60px;
    }
    input:last-of-type{
      margin-top: 22px;
    }
  }
}

.ProfileCompletedWrapper{
  width: 100%;
  height: 100vh;
  background: $light-dark-color;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  svg{
    position: relative;
    left: 50%;
    transform: translateX(-50%) translateY(-200px)
  }
  &__link{
    font-family: $font-family;
    text-decoration: none;
    color: #ffffff;
    font-weight: $font-semibold;
    font-size: 34px;
    align-self: center;
    transform: translateY(-50px);
  }
}
